export const APP_NAME = 'RightSize'

export const BROADCAST_CHANNEL = 'rs_bc'

export const REGEX = {
  PUNCTUATION: /[.,/#!$%^&*;:{}=\-_`~()]/g,
  URL: /@^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$@iS/g // https://mathiasbynens.be/demo/url-regex
}

export const ICON = {
  CLOSE: 'close',
  LABEL: 'label',
  CHECK: 'check',
  DELETE: 'delete',
  INFO: 'info_outline',
  EMAIL: 'mail_outline',
  ERROR: 'error_outline',
  OPEN_IN_NEW: 'open_in_new',
  RADIO_SELECTED: 'check_circle',
  RADIO: 'radio_button_unchecked',
  SUCCESS: 'check_circle_outline',
  CHECKBOX: 'check_box_outline_blank',
  CHECKBOX_CHECKED: 'check_box'
}

export const MODE = {
  VERIFY_EMAIL: 'verifyEmail',
  RESET_PASSWORD: 'resetPassword'
}

export const CLOUD_FUNCTION = {
  CLIENT_INVITE_USER: 'clients-clientInviteUser',
  USER_SET_CUSTOM_CLAIMS: 'users-userSetCustomClaims',
  BANNER_GET_SIZE_RECOMMENDATION: 'banner-getSizeRecommendation'
}

export const COOKIE = {
  LOCALE: 'NEXT_LOCALE',
  RECOMMENDATION: 'rs_re',
  CLIENT_HOSTNAME: 'rs_ho',
  REFRESH_TOKEN: 'rs_rt',
  ID_TOKEN: 'rs_it'
}