export const DB = {

  ALL: 'all',
  
  SHOWS: 'shows',
  USERS: 'users',
  CLIENTS: 'clients',

  USER: {
    REF: 'ref',
    NAME: 'name',
    TEAM: 'team',
    EMAIL: 'email',
    SHOES: 'shoes',
    SECTION: 'section',
    MEASUREMENT: {
      _: 'measurement',
      MM: 'mm',
      VALUE: 'value',
      SYSTEM: 'system'
    },
    SHOE: {
      LINE: 'line',
      TYPE: 'type',
      SIZE: 'size',
      BRAND: 'brand',
      LOCALE: 'locale',
      SIZE_VALUE: 'sizeValue'
    }
  },

  CLIENT: {
    URL: 'url',
    NAME: 'name',
    ID: 'clientId',
    EMAIL: 'email',
    USERS: 'users',
    SHOWS: 'shows',
    HOSTNAME: 'hostname',
    CREATED_ON: 'createdOn'
  },

  SHOES: {
    _: 'shoes',
    SRC: 'src',
    SIZES: 'sizes',
    NAMES: {
      _: 'names',
      BRANDS: 'brands',
      LINES: 'lines'
    },
    DIFFS: 'diffs',
    DIFFS_DATA: 'diffsData',
    PRIMARY_LOCALES: 'primaryLocales',
    SIZES_CUSTOM_USERS: 'sizesCustomUsers',
    SIZES_CUSTOM_CLIENTS: 'sizesCustomClients',
    SIZES_CUSTOM_CLIENT_USERS: 'sizesCustomClientUsers',
    LOCALES: {
      EU: 'eu',
      US: 'us',
      UK: 'uk',
      SML: 'sml'
    },
    SECTIONS: {
      MEN: 'men',
      KIDS: 'kids',
      WOMEN: 'women',
      UNISEX: 'unisex'
    }
  }
}