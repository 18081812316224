
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Image from 'next/image'
import router from 'next/router'
import styled from '@emotion/styled'
import {GetServerSideProps} from 'next'

import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

import {PATH, ICON, I18N} from 'utils/constants'
import {checkUser, temp} from 'utils/helpers-server'
import {Logo, Button, PageLegalFooter, PageHeader} from 'components'

import g from 'public/images/g.svg'
import benefit_revenue from 'public/images/benefit_revenue.svg'
import benefit_returns from 'public/images/benefit_returns.svg'


const Page = styled.div`
  height: auto; // important for sticky header
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    & h1 {
      font-size: ${({theme}) => theme.size_2};
      line-height: ${({theme}) => theme.size_2};
    }
  }
`
const Contents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    flex-wrap: wrap;
  }
`
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${({theme}) => theme.content};
  padding: calc(3 * ${({theme}) => theme.size_3}) ${({theme}) => theme.contentPadding};
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    padding: ${({theme}) => theme.size_3} ${({theme}) => theme.contentPadding};
  }
`
const Section = styled(({children, ...props}) => <div {...props}><Container><Contents>{children}</Contents></Container></div>)`
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: left;
  background-color: ${({theme}) => theme.colorWhite};
  @media (min-width: ${({theme}) => theme.breakpoint}) {
    background-position: center;
  }
`
const Splash = styled(Section)`
  height: 700px;
  color: ${({theme}) => theme.colorWhite};
  background-image: url(/images/splash.jpg);
  & ${Container} {
    & ${Contents} {
      min-width: 240px;
      max-width: 370px;
      align-items: flex-start;
      & h1 {
        margin-bottom: calc(2 * ${({theme}) => theme.size_2});
      }
      & button {
        margin-top: ${({theme}) => theme.size};
      }
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    height: auto;
  }
`
const Challenge = styled(Section)`
  & ${Container} {
    & ${Contents} {
      & h1 {
        font-size: ${({theme}) => theme.size_1_5};
        & span {
          text-decoration: underline;
          text-decoration-color: ${({theme}) => theme.color};
          text-underline-offset: ${({theme}) => theme.size_0_5};
          text-decoration-thickness: ${({theme}) => theme.lineThick};
        }
      }
      & > div {
        display: flex;
        justify-content: space-evenly;
        & > div {
          display: flex;
          align-items: center;
          gap: ${({theme}) => theme.size};
          margin: 0 calc(2 * ${({theme}) => theme.size_2});
          & h1 {
            color: ${({theme}) => theme.color};
            font-size: calc(2 * ${({theme}) => theme.size_3});
            @media (max-width: ${({theme}) => theme.breakpoint}) {
              font-size: ${({theme}) => theme.size};
            }
          }
          @media (max-width: ${({theme}) => theme.breakpoint}) {
            margin: 0;
            gap: ${({theme}) => theme.size_0_5};
          }
        }
        @media (max-width: ${({theme}) => theme.breakpoint}) {
          justify-content: flex-start;
          gap: ${({theme}) => theme.size_0_5};
        }
      }
    }
  }
`
const Solution = styled(Section)`
  height: 600px;
  color: ${({theme}) => theme.colorWhite};
  background-image: url(/images/solution.jpeg);
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    height: auto;
  }
`
const Benefits = styled(Section)`
  & ${Container} {
    & ${Contents} {
      flex-direction: initial;
      justify-content: space-evenly;
      gap: ${({theme}) => theme.size_2};
      & > div {
        display: flex;
        align-items: center;
        gap: ${({theme}) => theme.size};
        & > span {
          flex: 2;
        }
        & > div {
          flex: 3;
          & h1 {
            font-size: ${({theme}) => theme.size_1_5};
          }
        }
        @media (max-width: ${({theme}) => theme.breakpoint}) {
          flex-direction: column;
          & > span {
            max-width: 40% !important;
          }
        }
      }
    }
  }
`
const Features = styled(Section)`
  height: 600px;
  color: ${({theme}) => theme.colorWhite};
  background-image: url(/images/features.jpeg);
  & ${Container} {
    & ${Contents} {
      align-items: flex-start;
    }
  }
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    height: auto;
    & p {
      margin-bottom: 0;
    }
  }
`
const Demo = styled(Section)`
  & h1 {
    font-size: ${({theme}) => theme.size_1_5};
  }
  @media (max-width: ${({theme}) => theme.breakpoint}) {
    justify-content: flex-start;
  }
`
const Footer = styled(Section)`
  background-color: ${({theme}) => theme.colorGrayDark};
  & ${Container} {
    padding-bottom: 0;
    padding-top: ${({theme}) => theme.size_3};
    & ${Contents} {
      flex-direction: initial;
      align-items: flex-start;
      & > h1 {
        min-width: 220px;
        margin-bottom: ${({theme}) => theme.size_3};
      }
      & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: ${({theme}) => theme.colorWhite} !important;
        & button {
          text-align: left;
          margin-left: 0 !important;
          color: ${({theme}) => theme.colorWhite} !important;
        }
        & > div {
          min-width: 180px;
          max-width: 220px;
          margin-bottom: ${({theme}) => theme.size_3};
          & h2 {
            color: ${({theme}) => theme.colorGray};
            margin-top: ${({theme}) => theme.size_0_5};
          }
          &:first-of-type {
            margin-right: ${({theme}) => theme.size_2};
            & button {
              display: block;
              &:last-of-type {
                margin-top: ${({theme}) => theme.size};
              }
            }
          }
          &:last-of-type {
            & button {
              width: 100%;
              margin-top: ${({theme}) => theme.size};
              background-color: ${({theme}) => theme.colorTransparent};
              &:hover {
                border-color: ${({theme}) => theme.colorWhite};
                background-color: ${({theme}) => theme.colorGrayTransparent};
              }
              &:last-of-type {
                margin-top: ${({theme}) => theme.size_2};
              }
            }
            & span {
              color: ${({theme}) => theme.colorGray};
            }
          }
        }
      }
    }
  }
`

const Home = () => {

  const {t, lang} = useTranslation()

  return(
    <Page>
      <PageHeader />
      <Splash>
        <h2>{t(`${I18N.HOME}:splash.subHeading`)}</h2>
        <h1>{t(`${I18N.HOME}:splash.heading`)}</h1>
        <Button onClick={() => router.push(PATH.CLIENT_SIGN_UP)}>{t(`${I18N.COMMON}:action.startFreeTrialDays`)}</Button>
        <Button secondary light onClick={() => window.open(process.env.NODE_ENV === 'production' ? `https://www.rightsize.cc${PATH.DEMO}` : `http://localhost:3000${PATH.DEMO}`,'_blank')}>{t(`${I18N.HOME}:demo.seeDemo`)}</Button>
      </Splash>
      <Challenge>
        <h1><Trans i18nKey={`${I18N.HOME}:challenge.intro`} components={{span: <span />}} /></h1>
        <div>
          <div>
            <h1>40%</h1>
            <h2>{t(`${I18N.HOME}:challenge.issue1`)}</h2>
          </div>
          <div>
            <h1>#1</h1>
            <h2>{t(`${I18N.HOME}:challenge.issue2`)}</h2>
          </div>
        </div>
        <h1><Trans i18nKey={`${I18N.HOME}:challenge.outro`} components={{span: <span />}} /></h1>
      </Challenge>
      <Solution>
        <h1>{t(`${I18N.HOME}:solution`)}</h1>
      </Solution>
      <Benefits>
        <div>
          <Image src={benefit_revenue} alt={t(`${I18N.HOME}:benefits.1.imageAlt`)} width='150' height='150' />
          <div>
            <h1>{t(`${I18N.HOME}:benefits.1.heading`)}</h1>
            <p>{t(`${I18N.HOME}:benefits.1.body`)}</p>
          </div>
        </div>
        <div>
          <Image src={benefit_returns} alt={t(`${I18N.HOME}:benefits.2.imageAlt`)} width='150' height='150' />
          <div>
            <h1>{t(`${I18N.HOME}:benefits.2.heading`)}</h1>
            <p>{t(`${I18N.HOME}:benefits.2.body`)}</p>
          </div>
        </div>
      </Benefits>
      <Features>
        <h1>{t(`${I18N.HOME}:features.intro`)}</h1>
        <p>&#8594; {t(`${I18N.HOME}:features.1`)}</p>
        <p>&#8594; {t(`${I18N.HOME}:features.2`)}</p>
        <p>&#8594; {t(`${I18N.HOME}:features.3`)}</p>
      </Features>
      <Demo>
        <h1>{t(`${I18N.HOME}:demo.heading`)}</h1>
        <Button secondary onClick={() => window.open(process.env.NODE_ENV === 'production' ? `https://www.rightsize.cc${PATH.DEMO}` : `http://localhost:3000${PATH.DEMO}`,'_blank')}>{t(`${I18N.HOME}:demo.seeDemo`)}</Button>
      </Demo>
      <Footer>
        <Logo />
        <div>
          <div>
            <h2>{t(`${I18N.HOME}:footer.store`)}</h2>
            <Button link onClick={() => router.push(PATH.DOCS)}>{t(`${I18N.COMMON}:header.docs`)}</Button>
            <Button link onClick={() => router.push(PATH.PRICING)}>{t(`${I18N.COMMON}:header.pricing`)}</Button>
            <Button link onClick={() => router.push(PATH.SUPPORT)}>{t(`${I18N.COMMON}:footer.support`)}</Button>
            <Button link onClick={() => router.push(PATH.CLIENT_SIGN_IN)}>{t(`${I18N.COMMON}:action.signIn`)}</Button>
            <Button onClick={() => router.push(PATH.CLIENT_SIGN_UP)}>{t(`${I18N.COMMON}:action.startFreeTrial`)}</Button>
          </div>
          <div>
            <h2>{t(`${I18N.HOME}:footer.shopper`)}</h2>
            <Button secondary img={g} onClick={() => import('utils/helpers/auth').then(({signInWithGoogle}) => signInWithGoogle(lang))}>{t(`${I18N.COMMON}:action.signInWithGoogle`)}</Button>
            <Button secondary icon={ICON.EMAIL} onClick={() => router.push({pathname: PATH.USER_SIGN_IN, query: {noSocial: true}}, PATH.USER_SIGN_IN)}>{t(`${I18N.COMMON}:action.signInWithEmail`)}</Button>
            <Button secondary onClick={() => router.push(PATH.USER_SIGN_UP)}>{t(`${I18N.COMMON}:action.createAccount`)}</Button>
          </div>
        </div>
      </Footer>
      <PageLegalFooter landing />
    </Page>
  )
}

 const _getServerSideProps: GetServerSideProps = async context => {

  // temp()

  return (
    await checkUser(context) || {props: {}}
  )
}

export default Home


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  