export const BANNER = {
  USER: 'user',
  ERROR: 'error',
  NO_USER: 'no_user',
  NO_SIZE: 'no_size',
  PREVIEW: 'preview',
  WAITING: 'waiting',
  USER_INCOMPLETE: 'user_incomplete',
  WRONG_SECTION_MEN: 'wrong_section_men',
  WRONG_SECTION_WOMEN: 'wrong_section_women'
} as const