export const I18N = {
  HOME: 'home',
  USER: 'user',
  DOCS: 'docs',
  STORE: 'store',
  CLIENT: 'client',
  COMMON: 'common',
  BANNER: 'banner',
  ACCOUNT: 'account',
  PRICING: 'pricing',
  SUPPORT: 'support',
  USER_SIGN_UP: 'user-signup',
  CLIENT_SIGNOUT: 'client-signout',
  USER_SIGN_UP_COMPLETE: 'user-signup-complete',
  SUPPORT_SHOPIFY_THEME_APP_BLOCK: 'support-shopify-theme-app-block',
  SUPPORT_SHOPIFY_GET_PAGE_ELEMENT: 'support-shopify-get-page-element'
}
