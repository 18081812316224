export const MEASUREMENT_SYSTEM = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
}

export const EMPTY_MEASUREMENT = {
  mm: 0, value: '', system: MEASUREMENT_SYSTEM.METRIC
}

export const SIZES = {
  
  IMPERIAL: [
    '8',
    '8 1/8',
    '8 2/8',
    '8 3/8',
    '8 4/8',
    '8 5/8',
    '8 6/8',
    '8 7/8',
    '9',
    '9 1/8',
    '9 2/8',
    '9 3/8',
    '9 4/8',
    '9 5/8',
    '9 6/8',
    '9 7/8',
    '10',
    '10 1/8',
    '10 2/8',
    '10 3/8',
    '10 4/8',
    '10 5/8',
    '10 6/8',
    '10 7/8',
    '11',
    '11 1/8',
    '11 2/8',
    '11 3/8',
    '11 4/8',
    '11 5/8',
    '11 6/8',
    '11 7/8',
    '12',
    '12 1/8',
    '12 2/8',
    '12 3/8',
    '12 4/8',
    '12 5/8',
    '12 6/8',
    '12 7/8',
    '13',
    '13 1/8',
    '13 2/8',
    '13 3/8',
    '13 4/8',
    '13 5/8',
    '13 6/8',
    '13 7/8'
  ],

  US_UK: [
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
    '5.5',
    '6',
    '6.5',
    '7',
    '7.5',
    '8',
    '8.5',
    '9',
    '9.5',
    '10',
    '10.5',
    '11',
    '11.5',
    '12',
    '12.5',
    '13',
    '13.5',
    '14',
    '14.5',
    '15',
    '15.5',
    '16',
    '16.5',
    '17',
    '17.5',
    '18'
  ],

  get US() {return this.US_UK},
  get UK() {return this.US_UK},

  EU: [
    '36',
    '36 1/3',
    '36 1/2',
    '36 2/3',
    '37',
    '37 1/3',
    '37 1/2',
    '37 2/3',
    '38',
    '38 1/3',
    '38 1/2',
    '38 2/3',
    '39',
    '39 1/3',
    '39 1/2',
    '39 2/3',
    '40',
    '40 1/3',
    '40 1/2',
    '40 2/3',
    '41',
    '41 1/3',
    '41 1/2',
    '41 2/3',
    '42',
    '42 1/3',
    '42 1/2',
    '42 2/3',
    '43',
    '43 1/3',
    '43 1/2',
    '43 2/3',
    '44',
    '44 1/3',
    '44 1/2',
    '44 2/3',
    '45',
    '45 1/3',
    '45 1/2',
    '45 2/3',
    '46',
    '46 1/3',
    '46 1/2',
    '46 2/3',
    '47',
    '47 1/3',
    '47 1/2',
    '47 2/3',
    '48',
    '48 1/3',
    '48 1/2',
    '48 2/3',
    '49',
    '49 1/3',
    '49 1/2',
    '49 2/3',
    '50',
    '50 1/3',
    '50 1/2',
    '50 2/3',
    '51',
    '51 1/3',
    '51 1/2',
    '51 2/3',
    '52',
    '52 1/3',
    '52 1/2',
    '52 2/3',
    '53',
    '53 1/3',
    '53 1/2',
    '53 2/3',
    '54',
    '54 1/3',
    '54 1/2',
    '54 2/3'
  ],

  SML: [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    'XXXL'
  ]
}