import dynamic from 'next/dynamic'

// export const Button = dynamic(() => import('@mui/material/Button'))

// ui props
import type {Props as LogoProps} from 'components/ui/Logo'
import type {Props as CardProps} from 'components/ui/Card'
import type {Props as CodeProps} from 'components/ui/Code'
import type {Props as TabsProps} from 'components/ui/Tabs'
import type {Props as IconProps} from 'components/ui/Icon'
import type {Props as AlertProps} from 'components/ui/Alert'
import type {Props as ModalProps} from 'components/ui/Modal'
import type {Props as InputProps} from 'components/ui/Input'
import type {Props as TableProps} from 'components/ui/Table'
import type {Props as ButtonProps} from 'components/ui/Button'
import type {Props as FadeHOCProps} from 'components/ui/FadeHOC'
import type {Props as SpinnerProps} from 'components/ui/Spinner'
import type {Props as CheckBoxProps} from 'components/ui/CheckBox'
import type {Props as PageHeaderProps} from 'components/ui/PageHeader'
import type {Props as FormButtonsProps} from 'components/ui/FormButtons'
import type {Props as CollapsibleProps} from 'components/ui/Collapsible'
import type {Props as InputFormikProps} from 'components/ui/InputFormik'
import type {Props as ProfileFieldProps} from 'components/ui/ProfileField'
import type {Props as ProfileValueProps} from 'components/ui/ProfileValue'
import type {Props as PageLegalFooterProps} from 'components/ui/PageLegalFooter'

// user props
import type {Props as ShoesProps} from 'components/user/Shoes'
import type {Props as AddShoeProps} from 'components/user/AddShoe'
import type {Props as ProfileProps} from 'components/user/Profile'
import type {Props as MeasurementProps} from 'components/user/Measurement'
import type {Props as TeamInvitationProps} from 'components/user/TeamInvitation'
import type {Props as MeasurementInputProps} from 'components/user/MeasurementInput'
import type {Props as SignUpStep1Props} from 'components/user/signup/Step1'
import type {Props as SignUpStep2Props} from 'components/user/signup/Step2'
import type {Props as SignUpStep3Props} from 'components/user/signup/Step3'
import type {Props as CompleteRegistrationProps} from 'components/user/signup/CompleteRegistration'

// client props
import type {Props as DocsProps} from 'components/client/Docs'
import type {Props as TeamProps} from 'components/client/Team'
import type {Props as SizesProps} from 'components/client/Sizes'
import type {Props as StatsProps} from 'components/client/Stats'
import type {Props as BillingProps} from 'components/client/Billing'
import type {Props as ClientProfileProps} from 'components/client/Profile'

// account props
import type {Props as SignInProps} from 'components/account/SignIn'
import type {Props as VerifyEmailProps} from 'components/account/VerifyEmail'
import type {Props as CreatePasswordProps} from 'components/account/CreatePassword'

// other props
import type {Props as DemoProps} from 'components/Demo'
import type {Props as RecommendationProps} from 'components/Recommendation'


// ui
export const Logo = dynamic<LogoProps>(() => import('components/ui/Logo'))
export const Card = dynamic<CardProps>(() => import('components/ui/Card'))
export const Code = dynamic<CodeProps>(() => import('components/ui/Code'))
export const Tabs = dynamic<TabsProps>(() => import('components/ui/Tabs'))
export const Icon = dynamic<IconProps>(() => import('components/ui/Icon'))
export const Alert = dynamic<AlertProps>(() => import('components/ui/Alert'))
export const Modal = dynamic<ModalProps>(() => import('components/ui/Modal'))
export const Input = dynamic<InputProps>(() => import('components/ui/Input'))
export const Table = dynamic<TableProps>(() => import('components/ui/Table'))
export const Button = dynamic<ButtonProps>(() => import('components/ui/Button'))
export const FadeHOC = dynamic<FadeHOCProps>(() => import('components/ui/FadeHOC'))
export const Spinner = dynamic<SpinnerProps>(() => import('components/ui/Spinner'))
export const CheckBox = dynamic<CheckBoxProps>(() => import('components/ui/CheckBox'))
export const PageHeader = dynamic<PageHeaderProps>(() => import('components/ui/PageHeader'))
export const FormButtons = dynamic<FormButtonsProps>(() => import('components/ui/FormButtons'))
export const Collapsible = dynamic<CollapsibleProps>(() => import('components/ui/Collapsible'))
export const InputFormik = dynamic<InputFormikProps>(() => import('components/ui/InputFormik'))
export const ProfileField = dynamic<ProfileFieldProps>(() => import('components/ui/ProfileField'))
export const ProfileValue = dynamic<ProfileValueProps>(() => import('components/ui/ProfileValue'))
export const PageLegalFooter = dynamic<PageLegalFooterProps>(() => import('components/ui/PageLegalFooter'))
export const LanguageSelector = dynamic(() => import('components/ui/LanguageSelector'))
export const RightSize = dynamic(() => import('components/ui/RightSize'))

// user
export const Shoes = dynamic<ShoesProps>(() => import('components/user/Shoes'))
export const AddShoe = dynamic<AddShoeProps>(() => import('components/user/AddShoe'))
export const Profile = dynamic<ProfileProps>(() => import('components/user/Profile'))
export const Measurement = dynamic<MeasurementProps>(() => import('components/user/Measurement'))
export const TeamInvitation = dynamic<TeamInvitationProps>(() => import('components/user/TeamInvitation'))
export const MeasurementInput = dynamic<MeasurementInputProps>(() => import('components/user/MeasurementInput'))
export const MeasurementImage = dynamic(() => import('components/user/MeasurementImage'))
export const Step1 = dynamic<SignUpStep1Props>(() => import('components/user/signup/Step1'))
export const Step2 = dynamic<SignUpStep2Props>(() => import('components/user/signup/Step2'))
export const Step3 = dynamic<SignUpStep3Props>(() => import('components/user/signup/Step3'))
export const CompleteRegistration = dynamic<CompleteRegistrationProps>(() => import('components/user/signup/CompleteRegistration'))

// client
export const Docs = dynamic<DocsProps>(() => import('components/client/Docs'))
export const Team = dynamic<TeamProps>(() => import('components/client/Team'))
export const Sizes = dynamic<SizesProps>(() => import('components/client/Sizes'))
export const Stats = dynamic<StatsProps>(() => import('components/client/Stats'))
export const Billing = dynamic<BillingProps>(() => import('components/client/Billing'))
export const ClientProfile = dynamic<ClientProfileProps>(() => import('components/client/Profile'))

// account
export const SignIn = dynamic<SignInProps>(() => import('components/account/SignIn'))
export const VerifyEmail = dynamic<VerifyEmailProps>(() => import('components/account/VerifyEmail'))
export const CreatePassword = dynamic<CreatePasswordProps>(() => import('components/account/CreatePassword'))

// other
export const Demo = dynamic<DemoProps>(() => import('components/Demo'))
export const Recommendation = dynamic<RecommendationProps>(() => import('components/Recommendation'))
